import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spells-call-lightning-to-curse-water"></a><h2>Spells</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Call Lightning - Curse Water</span>
    </h2>
    <h6><a id="call-lightning">Call Lightning</a></h6>
    <p className="initial"><i>Evocation [Electricity]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: One or more
30-ft.-long
vertical lines of lightning</span> <span className="stat-block"><b>Duration</b>:
1 min./level</span> <span className="stat-block"><b>Saving Throw</b>:
Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>Immediately upon completion of the spell, and once per round
thereafter, you may call down a 5-foot-wide, 30-foot-long, vertical
bolt of lightning that deals 3d6 points of electricity damage. The bolt
of lightning flashes down in a vertical stroke at whatever target point
you choose within the spell&rsquo;s range (measured from your position
at the
time). Any creature in the target square or in the path of the bolt is
affected.</p>
    <p>You need not call a bolt of lightning immediately; other
actions, even spellcasting, can be performed. However, each round after
the first you may use a standard action (concentrating on the spell) to
call a bolt. You may call a total number of bolts equal to your caster
level (maximum 10 bolts).</p>
    <p>If you are outdoors and in a stormy area&mdash;a rain shower,
clouds
and wind, hot and cloudy conditions, or even a tornado (including a
whirlwind formed by a djinni or an air elemental of at least Large
size)&mdash;each bolt deals 3d10 points of electricity damage instead
of 3d6.</p>
    <p>This spell functions indoors or underground but not underwater.</p>
    <h6><a id="call-lightning-storm">Call Lightning Storm</a></h6>
    <p className="initial"><i>Evocation [Electricity]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 5</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#call-lightning">call lightning</a>, except that each
bolt deals 5d6 points of electricity damage (or 5d10 if created
outdoors in a stormy area), and you may call a maximum of 15 bolts.</p>
    <h6><a id="calm-animals">Calm Animals</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Animal 1, Drd 1, Rgr 1</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Animals within
30 ft. of
each other</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell soothes and quiets animals, rendering them docile
and harmless. Only ordinary animals (those with Intelligence scores of
1 or 2) can be affected by this spell. All the subjects must be of the
same kind, and no two may be more than 30 feet apart. The maximum
number of Hit Dice of animals you can affect is equal to 2d4 + caster
level. A dire animal or an animal trained to attack or guard is allowed
a saving throw; other animals are not.</p>
    <p>The affected creatures remain where they are and do not attack
or flee. They are not <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> and defend themselves
normally if
attacked. Any threat breaks the spell on the threatened creatures.</p>
    <h6><a id="calm-emotions">Calm Emotions</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2, Law 2</span>
    <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Creatures in a
20-ft.-radius spread</span> <span className="stat-block"><b>Duration</b>:
Concentration, up to 1
round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>This spell calms agitated creatures. You have no control over
the affected creatures, but calm emotions can stop raging creatures
from fighting or joyous ones from reveling. Creatures so affected
cannot take violent actions (although they can defend themselves) or do
anything destructive. Any aggressive action against or damage dealt to
a calmed creature immediately breaks the spell on all calmed creatures.</p>
    <p>This spell automatically suppresses (but does not dispel) any
morale bonuses granted by spells such as bless, good hope, and rage, as
well as negating a bard&rsquo;s ability to inspire courage or a
barbarian&rsquo;s
rage ability. It also suppresses any fear effects and removes the <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a> condition from all
targets. While the spell lasts, a
suppressed spell or effect has no effect. When the calm emotions spell
ends, the original spell or effect takes hold of the creature again,
provided that its duration has not expired in the meantime.</p>
    <h6><a id="cats-grace">Cat&rsquo;s Grace</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Drd 2, Rgr 2,
Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates
(harmless)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The transmuted creature becomes more graceful, agile, and
coordinated. The spell grants a +4 enhancement bonus to Dexterity,
adding the usual benefits to AC, Reflex saves, and other uses of the
Dexterity modifier.</p>
    <p><i>Material Component</i>: A pinch of cat fur.</p>
    <h6><a id="mass-cats-grace">Cat&rsquo;s Grace, Mass</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Drd 6, Sor/Wiz 6</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#cats-grace">cat&rsquo;s grace</a>, except that it
affects
multiple creatures.</p>
    <h6><a id="cause-fear">Cause Fear</a></h6>
    <p className="initial"><i>Necromancy [Fear, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Clr 1, Death 1,
Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature with
5 or fewer HD</span> <span className="stat-block"><b>Duration</b>: 1d4
rounds or 1 round;
see text</span> <span className="stat-block"><b>Saving Throw</b>: Will
partial</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The affected creature becomes <a href="abilitiesAndConditions.html#frightened" style={{
        "color": "rgb(87, 158, 182)"
      }}>frightened</a>. If the subject
succeeds on a Will save, it is <a href="abilitiesAndConditions.html#shaken" style={{
        "color": "rgb(87, 158, 182)"
      }}>shaken</a> for 1 round. Creatures
with 6 or
more Hit Dice are immune to this effect.</p>
    <p>Cause fear counters and dispels remove fear.</p>
    <h6><a id="chain-lightning">Chain Lightning</a></h6>
    <p className="initial"><i>Evocation [Electricity]</i></p>
    <span className="stat-block"><b>Level</b>: Air 6, Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Targets</b>: One primary
target, plus
one secondary target/level (each of which must be within 30 ft. of the
primary target)</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>This spell creates an electrical discharge that begins as a
single stroke commencing from your fingertips. Unlike lightning bolt,
chain lightning strikes one object or creature initially, then arcs to
other targets.</p>
    <p>The bolt deals 1d6 points of electricity damage per caster
level (maximum 20d6) to the primary target. After it strikes, lightning
can arc to a number of secondary targets equal to your caster level
(maximum 20). The secondary bolts each strike one target and deal half
as much damage as the primary one did (rounded down).</p>
    <p>Each target can attempt a Reflex saving throw for half damage.
You choose secondary targets as you like, but they must all be within
30 feet of the primary target, and no target can be struck more than
once. You can choose to affect fewer secondary targets than the maximum.</p>
    <p><i>Focus</i>: A bit of fur; a piece of amber, glass, or a
crystal rod; plus one silver pin for each of your caster levels.</p>
    <h6><a id="changestaff">Changestaff</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 7</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Your touched staff</span> <span className="stat-block"><b>Duration</b>: 1
hour/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You change a specially prepared quarterstaff into a Huge
treantlike creature, about 24 feet tall. When you plant the end of the
staff in the ground and speak a special command to conclude the casting
of the spell, your staff turns into a creature that looks and fights
just like a treant. The staff-treant defends you and obeys any spoken
commands. However, it is by no means a true treant; it cannot converse
with actual treants or control trees. If the staff-treant is reduced to
0 or fewer hit points, it crumbles to powder and the staff is
destroyed. Otherwise, the staff returns to its normal form when the
spell duration expires (or when the spell is dismissed), and it can be
used as the focus for another casting of the spell. The staff-treant is
always at full strength when created, despite any wounds it may have
incurred the last time it appeared.</p>
    <p><i>Focus</i>: The quarterstaff, which must be specially
prepared. The staff must be a sound limb cut from an ash, oak, or yew,
then cured, shaped, carved, and polished (a process requiring
twenty-eight days).</p>
    <p>You cannot adventure or engage in other strenuous activity
during the shaping and carving of the staff.</p>
    <h6><a id="chaos-hammer">Chaos Hammer</a></h6>
    <p className="initial"><i>Evocation [Chaotic]</i></p>
    <span className="stat-block"><b>Level</b>: Chaos 4</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous
(1d6
rounds); see text</span> <span className="stat-block"><b>Saving Throw</b>:
Will partial; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You unleash chaotic power to smite your enemies. The power
takes the form of a multicolored explosion of leaping, ricocheting
energy. Only lawful and neutral (not chaotic) creatures are harmed by
the spell.</p>
    <p>The spell deals 1d8 points of damage per two caster levels
(maximum 5d8) to lawful creatures (or 1d6 points of damage per caster
level, maximum 10d6, to lawful outsiders) and slows them for 1d6 rounds.</p>
    <p>In
addition, it takes a &ndash;2 penalty to AC, attack rolls, damage
rolls, and
Reflex saves (see the <a href="spellsS.html#slow" style={{
        "color": "rgb(87, 158, 182)"
      }}>slow</a> spell). A successful Will
save reduces the damage by half and
negates the slow effect.</p>
    <p>The spell deals only half damage against creatures who are
neither lawful nor chaotic, and they are not slowed. Such a creature
can reduce the damage by half again (down to one-quarter) with a
successful Will save.</p>
    <h6><a id="charm-animal">Charm Animal</a></h6>
    <p className="initial"><i>Enchantment (Charm) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 1, Rgr 1</span> <span className="stat-block"><b>Target</b>: One animal</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#charm-person">charm person</a>, except that it affects
a creature of the animal type.</p>
    <h6><a id="charm-monster">Charm Monster</a></h6>
    <p className="initial"><i>Enchantment (Charm) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 4</span> <span className="stat-block"><b>Target</b>: One living creature</span> <span className="stat-block"><b>Duration</b>: One day/level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#charm-person">charm person</a>, except that the effect
is not restricted by creature type or size.</p>
    <h6><a id="mass-charm-monster">Charm Monster, Mass</a></h6>
    <p className="initial"><i>Enchantment (Charm) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Targets</b>: One or more creatures,
no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: One day/level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#charm-monster">charm monster</a>, except that mass
charm monster affects a number of creatures whose combined HD do not
exceed twice your level, or at least one creature regardless of HD. If
there are more potential targets than you can affect, you choose them
one at a time until you choose a creature with too many HD.</p>
    <h6><a id="charm-person">Charm Person</a></h6>
    <p className="initial"><i>Enchantment (Charm) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One humanoid
creature</span> <span className="stat-block"><b>Duration</b>: 1 hour/level</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This charm makes a humanoid creature regard you as its trusted
friend and ally (treat the target&rsquo;s attitude as friendly). If the
creature is currently being threatened or attacked by you or your
allies, however, it receives a +5 bonus on its saving throw.</p>
    <p>The spell does not enable you to control the charmed person as
if it were an automaton, but it perceives your words and actions in the
most favorable way. You can try to give the subject orders, but you
must win an opposed Charisma check to convince it to do anything it
wouldn&rsquo;t ordinarily do. (Retries are not allowed.) An affected
creature
never obeys suicidal or obviously harmful orders, but it might be
convinced that something very dangerous is worth doing. Any act by you
or your apparent allies that threatens the charmed person breaks the
spell. You must speak the person&rsquo;s language to communicate your
commands, or else be good at pantomiming.</p>
    <h6><a id="chill-metal">Chill Metal</a></h6>
    <p className="initial"><i>Transmutation [Cold]</i></p>
    <span className="stat-block"><b>Level</b>: Drd 2</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: Metal equipment
of one
creature per two levels, no two of which can be more than 30 ft. apart;
or 25 lb. of metal/level, none of which can be more than 30 ft. away
from any of the rest</span> <span className="stat-block"><b>Duration</b>:
7 rounds</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>Chill metal makes metal extremely cold. Unattended, nonmagical
metal gets no saving throw. Magical metal is allowed a saving throw
against the spell. An item in a creature&rsquo;s possession uses the
creature&rsquo;s saving throw bonus unless its own is higher.</p>
    <p>A creature takes cold damage if its equipment is chilled. It
takes full damage if its armor is affected or if it is holding,
touching, wearing, or carrying metal weighing one-fifth of its weight.
The creature takes minimum damage (1 point or 2 points; see the table)
if it&rsquo;s not wearing metal armor and the metal that it&rsquo;s
carrying weighs
less than one-fifth of its weight.</p>
    <p>On the first round of the spell, the metal becomes chilly and
uncomfortable to touch but deals no damage. The same effect also occurs
on the last round of the spell&rsquo;s duration. During the second (and
also
the next-to-last) round, icy coldness causes pain and damage. In the
third, fourth, and fifth rounds, the metal is freezing cold, causing
more damage, as shown on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "75px"
          }}>Round</th>
          <th style={{
            "width": "125px"
          }}>Metal
Temperature</th>
          <th style={{
            "width": "100px"
          }}>Damage</th>
        </tr>
        <tr className="odd-row">
          <td>1</td>
          <td>Cold</td>
          <td>None</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Icy</td>
          <td>1d4 points</td>
        </tr>
        <tr className="odd-row">
          <td>3&ndash;5</td>
          <td>Freezing</td>
          <td>2d4 points</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Icy</td>
          <td>1d4 points</td>
        </tr>
        <tr className="odd-row">
          <td>7</td>
          <td>Cold</td>
          <td>None</td>
        </tr>
      </tbody>
    </table>
    <p>Any heat intense enough to damage the creature negates cold
damage from the spell (and vice versa) on a point-for-point basis.
Underwater, chill metal deals no damage, but ice immediately forms
around the affected metal, making it more buoyant.</p>
    <p>Chill metal counters and dispels heat metal.</p>
    <h6><a id="chill-touch">Chill Touch</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Targets</b>:
Creature or creatures
touched (up to one/level)</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude partial
or Will negates; see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes</span>
    <p>A touch from your hand, which glows with blue energy, disrupts
the life force of living creatures. Each touch channels negative energy
that deals 1d6 points of damage. The touched creature also takes 1
point of Strength damage unless it makes a successful Fortitude saving
throw. You can use this melee touch attack up to one time per level.</p>
    <p>An undead creature you touch takes no damage of either sort,
but it must make a successful Will saving throw or flee as if <a href="abilitiesAndConditions.html#panicked" style={{
        "color": "rgb(87, 158, 182)"
      }}>panicked</a>
for 1d4 rounds +1 round per caster level.</p>
    <h6><a id="circle-of-death">Circle of Death</a></h6>
    <p className="initial"><i>Necromancy [Death]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Area</b>: Several living
creatures
within a 40-ft.-radius burst</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
Yes</span>
    <p>A circle of death snuffs out the life force of living
creatures, killing them instantly.</p>
    <p>The spell slays 1d4 HD worth of living creatures per caster
level (maximum 20d4). Creatures with the fewest HD are affected first;
among creatures with equal HD, those who are closest to the
burst&rsquo;s
point of origin are affected first. No creature of 9 or more HD can be
affected, and Hit Dice that are not sufficient to affect a creature are
wasted.</p>
    <p><i>Material Component</i>: The powder of a crushed black pearl
with a minimum value of 500 gp.</p>
    <h6><a id="clairaudience-clairvoyance">Clairaudience/Clairvoyance</a></h6>
    <p className="initial"><i>Divination (Scrying)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Knowledge 3,
Sor/Wiz 3</span> <span className="stat-block"><b>Components</b>: V, S, F/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Effect</b>: Magical
sensor</span> <span className="stat-block"><b>Duration</b>: 1 min./level
(D)</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Clairaudience/clairvoyance creates an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> magical sensor
at a specific location that enables you to hear or see (your choice)
almost as if you were there. You don&rsquo;t need line of sight or line
of
effect, but the locale must be known&mdash;a place familiar to you or
an
obvious one. Once you have selected the locale, the sensor
doesn&rsquo;t
move, but you can rotate it in all directions to view the area as
desired. Unlike other scrying spells, this spell does not allow
magically or supernaturally enhanced senses to work through it. If the
chosen locale is magically dark, you see nothing. If it is naturally
pitch black, you can see in a 10- foot radius around the center of the
spell&rsquo;s effect. Clairaudience/clairvoyance functions only on the
plane
of existence you are currently occupying.</p>
    <p><i>Arcane Focus</i>: A small horn (for hearing) or a glass eye
(for seeing).</p>
    <h6><a id="clenched-fist">Clenched Fist</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8, Strength 8</span>
    <span className="stat-block"><b>Components</b>: V, S, F/DF</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#interposing-hand">interposing hand</a>, except that the
hand can interpose itself, push, or strike one opponent that you
select. The floating hand can move as far as 60 feet and can attack in
the same round. Since this hand is directed by you, its ability to
notice or attack <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> or concealed creatures
is no better than
yours.</p>
    <p>The hand attacks once per round, and its attack bonus equals
your caster level + your Intelligence, Wisdom, or Charisma modifier
(for a wizard, cleric, or sorcerer, respectively), +11 for the
hand&rsquo;s
Strength score (33), &ndash;1 for being Large. The hand deals 1d8+11
points
of damage on each attack, and any creature struck must make a Fortitude
save (against this spell&rsquo;s save DC) or be <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round.
Directing the spell to a new target is a move action.</p>
    <p>The clenched fist can also interpose itself as interposing
hand does, or it can <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull rush</a> an opponent as
forceful hand does, but
at a +15 bonus on the Strength check.</p>
    <p>Clerics who cast this spell name it for their deities.</p>
    <p><i>Arcane Focus</i>: A leather glove.</p>
    <h6><a id="cloak-of-chaos">Cloak of Chaos</a></h6>
    <p className="initial"><i>Abjuration [Chaotic]</i></p>
    <span className="stat-block"><b>Level</b>: Chaos 8, Clr 8</span> <span className="stat-block"><b>Components</b>: V, S, F</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 20 ft.</span> <span className="stat-block"><b>Targets</b>: One creature/level in a
20-ft.-radius burst centered on you</span> <span className="stat-block"><b>Duration</b>:
1 round/level (D)</span> <span className="stat-block"><b>Saving Throw</b>:
See text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(harmless)</span>
    <p>A random pattern of color surrounds the subjects, protecting
them from attacks, granting them resistance to spells cast by lawful
creatures, and causing lawful creatures that strike the subjects to
become <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a>. This abjuration has
four effects.</p>
    <p>First, each warded creature gains a +4 deflection bonus to AC
and a +4 resistance bonus on saves. Unlike protection from law, the
benefit of this spell applies against all attacks, not just against
attacks by lawful creatures.</p>
    <p>Second, each warded creature gains spell resistance 25 against
lawful spells and spells cast by lawful creatures.</p>
    <p>Third, the abjuration blocks possession and mental influence,
just as protection from law does.</p>
    <p>Finally, if a lawful creature succeeds on a melee attack
against a warded creature, the offending attacker is confused for 1
round (Will save negates, as with the confusion spell, but against the
save DC of cloak of chaos).</p>
    <p><i>Focus</i>: A tiny reliquary containing some sacred relic,
such as a scrap of parchment from a chaotic text. The reliquary costs
at least 500 gp.</p>
    <h6><a id="clone">Clone</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 8</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: 0 ft.</span> <span className="stat-block"><b>Effect</b>:
One clone</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell makes an inert duplicate of a creature. If the
original individual has been slain, its soul immediately transfers to
the clone, creating a replacement (provided that the soul is free and
willing to return). The original&rsquo;s physical remains, should they
still
exist, become inert and cannot thereafter be restored to life. If the
original creature has reached the end of its natural life span (that
is, it has died of natural causes), any cloning attempt fails.</p>
    <p>To create the duplicate, you must have a piece of flesh (not
hair, nails, scales, or the like) with a volume of at least 1 cubic
inch that was taken from the original creature&rsquo;s living body. The
piece
of flesh need not be fresh, but it must be kept from rotting. Once the
spell is cast, the duplicate must be grown in a laboratory for 2d4
months.</p>
    <p>When the clone is completed, the original&rsquo;s soul enters
it
immediately, if that creature is already <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>. The clone is physically
identical with the original and possesses the same personality and
memories as the original. In other respects, treat the clone as if it
were the original character raised from the dead, including the loss of
one level or 2 points of Constitution (if the original was a 1st-level
character). If this Constitution adjustment would give the clone a
Constitution score of 0, the spell fails. If the original creature has
lost levels since the flesh sample was taken and died at a lower level
than the clone would otherwise be, the clone is one level below the
level at which the original died.</p>
    <p>The spell duplicates only the original&rsquo;s body and mind,
not
its equipment.</p>
    <p>A duplicate can be grown while the original still lives, or
when the original soul is unavailable, but the resulting body is merely
a soulless bit of inert flesh, which rots if not preserved.</p>
    <p><i>Material Component</i>: The piece of flesh and various
laboratory supplies (cost 1,000 gp).</p>
    <p><i>Focus</i>: Special laboratory equipment (cost 500 gp).</p>
    <h6><a id="cloudkill">Cloudkill</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Effect</b>: Cloud
spreads in 20-ft.
radius, 20 ft. high</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude partial;
see text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell generates a bank of fog, similar to a fog cloud,
except that its vapors are yellowish green and poisonous. These vapors
automatically kill any living creature with 3 or fewer HD (no save). A
living creature with 4 to 6 HD is slain unless it succeeds on a
Fortitude save (in which case it takes 1d4 points of Constitution
damage on your turn each round while in the cloud).</p>
    <p>A living creature with 6 or more HD takes 1d4 points of
Constitution damage on your turn each round while in the cloud (a
successful Fortitude save halves this damage). Holding one&rsquo;s
breath
doesn&rsquo;t help, but creatures immune to poison are unaffected by
the
spell.</p>
    <p>Unlike a fog cloud, the cloudkill moves away from you at 10
feet per round, rolling along the surface of the ground.</p>
    <p>Figure out the cloud&rsquo;s new spread each round based on
its new
point of origin, which is 10 feet farther away from the point of origin
where you cast the spell.</p>
    <p>Because the vapors are heavier than air, they sink to the
lowest level of the land, even pouring down den or sinkhole openings.
It cannot penetrate liquids, nor can it be cast underwater.</p>
    <h6><a id="color-spray">Color Spray</a></h6>
    <p className="initial"><i>Illusion (Pattern) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 15 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous; see text</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>A vivid cone of clashing colors springs forth from your hand,
causing creatures to become <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a>, perhaps also <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a>, and possibly
knocking them <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>.</p>
    <p>Each creature within the cone is affected according to its Hit
Dice.</p>
    <p><i>2 HD or less</i>: The creature is unconscious, blinded, and
stunned for 2d4 rounds, then blinded and stunned for 1d4 rounds, and
then stunned for 1 round. (Only living creatures are knocked
unconscious.)</p>
    <p><i>3 or 4 HD</i>: The creature is blinded and stunned for 1d4
rounds, then stunned for 1 round.</p>
    <p><i>5 or more HD</i>: The creature is stunned for 1 round.</p>
    <p>Sightless creatures are not affected by color spray.</p>
    <p><i>Material Component</i>: A pinch each of powder or sand that
is colored red, yellow, and blue.</p>
    <h6><a id="command">Command</a></h6>
    <p className="initial"><i>Enchantment (Compulsion)
[Language-Dependent, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: 1 round</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates</span>
    <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>You give the subject a single command, which it obeys to the
best of its ability at its earliest opportunity. You may select from
the following options.</p>
    <p><i>Approach</i>: On its turn, the subject moves toward you as
quickly and directly as possible for 1 round. The creature may do
nothing but move during its turn, and it provokes attacks of
opportunity for this movement as normal.</p>
    <p><i>Drop</i>: On its turn, the subject drops whatever it is
holding. It can&rsquo;t pick up any dropped item until its next turn.</p>
    <p><i>Fall</i>: On its turn, the subject falls to the ground and
remains <a href="abilitiesAndConditions.html#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a> for 1 round. It may act
normally while prone but takes
any appropriate penalties.</p>
    <p><i>Flee</i>: On its turn, the subject moves away from you as
quickly as possible for 1 round. It may do nothing but move during its
turn, and it provokes attacks of opportunity for this movement as
normal.</p>
    <p><i>Halt</i>: The subject stands in place for 1 round. It may
not take any actions but is not considered <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>.</p>
    <p>If the subject can&rsquo;t carry out your command on its next
turn,
the spell automatically fails.</p>
    <h6><a id="greater-command">Command, Greater</a></h6>
    <p className="initial"><i>Enchantment (Compulsion)
[Language-Dependent, Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5</span> <span className="stat-block"><b>Targets</b>: One creature/level, no
two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 round/level</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#command">command</a>, except that up to one
creature per level may be affected, and the activities continue beyond
1 round. At the start of each commanded creature&rsquo;s action after
the
first, it gets another Will save to attempt to break free from the
spell. Each creature must receive the same command.</p>
    <h6><a id="command-plants">Command Plants</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 4, Plant 4, Rgr 3</span>
    <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Up to 2
HD/level of
plant creatures, no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: One day/level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell allows you some degree of control over one or more
plant creatures. Affected plant creatures can understand you, and they
perceive your words and actions in the most favorable way (treat their
attitude as friendly). They will not attack you while the spell lasts.
You can try to give a subject orders, but you must win an opposed
Charisma check to convince it to do anything it wouldn&rsquo;t
ordinarily do.
(Retries are not allowed.) A commanded plant never obeys suicidal or
obviously harmful orders, but it might be convinced that something very
dangerous is worth doing.</p>
    <p>You can affect a number of plant creatures whose combined
level or HD do not exceed twice your level.</p>
    <h6><a id="command-undead">Command Undead</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: One undead
creature</span> <span className="stat-block"><b>Duration</b>: One day/level</span>
    <span className="stat-block"><b>Saving Throw</b>: Will negates; see
text</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell allows you some degree of control over an undead
creature. Assuming the subject is intelligent, it perceives your words
and actions in the most favorable way (treat its attitude as friendly).
It will not attack you while the spell lasts. You can try to give the
subject orders, but you must win an opposed Charisma check to convince
it to do anything it wouldn&rsquo;t ordinarily do. (Retries are not
allowed.)
An intelligent commanded undead never obeys suicidal or obviously
harmful orders, but it might be convinced that something very dangerous
is worth doing.</p>
    <p>A nonintelligent undead creature gets no saving throw against
this spell. When you control a mindless being, you can communicate only
basic commands, such as &ldquo;come here,&rdquo; &ldquo;go
there,&rdquo; &ldquo;fight,&rdquo; &ldquo;stand
still,&rdquo; and so on. Nonintelligent undead won&rsquo;t resist
suicidal or
obviously harmful orders.</p>
    <p>Any act by you or your apparent allies that threatens the
commanded undead (regardless of its Intelligence) breaks the spell.</p>
    <p>Your commands are not telepathic. The undead creature must be
able to hear you.</p>
    <p><i>Material Component</i>: A shred of raw meat and a splinter
of bone.</p>
    <h6><a id="commune">Commune</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Clr 5</span> <span className="stat-block"><b>Components</b>: V, S, M, DF, XP</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
1 round/level</span>
    <p>You contact your deity&mdash;or agents thereof &mdash;and ask
questions
that can be answered by a simple yes or no. (A cleric of no particular
deity contacts a philosophically allied deity.) You are allowed one
such question per caster level. The answers given are correct within
the limits of the entity&rsquo;s knowledge. &ldquo;Unclear&rdquo; is a
legitimate answer,
because powerful beings of the Outer Planes are not necessarily
omniscient. In cases where a one-word answer would be misleading or
contrary to the deity&rsquo;s interests, a short phrase (five words or
less)
may be given as an answer instead.</p>
    <p>The spell, at best, provides information to aid character
decisions. The entities contacted structure their answers to further
their own purposes. If you lag, discuss the answers, or go off to do
anything else, the spell ends.</p>
    <p><i>Material Component</i>: Holy (or unholy) water and incense.</p>
    <p><i>XP Cost</i>: 100 XP.</p>
    <h6><a id="commune-with-nature">Commune with Nature</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Animal 5, Drd 5, Rgr 4</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span>
    <p>You become one with nature, attaining knowledge of the
surrounding territory. You instantly gain knowledge of as many as three
facts from among the following subjects: the ground or terrain, plants,
minerals, bodies of water, people, general animal population, presence
of woodland creatures, presence of powerful unnatural creatures, or
even the general state of the natural setting.</p>
    <p>In outdoor settings, the spell operates in a radius of 1 mile
per caster level. In natural underground settings&mdash;caves, caverns,
and
the like&mdash;the radius is limited to 100 feet per caster level. The
spell
does not function where nature has been replaced by construction or
settlement, such as in dungeons and towns.</p>
    <h6><a id="comprehend-languages">Comprehend Languages</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Clr 1, Sor/Wiz 1</span>
    <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span>
    <p>You can understand the spoken words of creatures or read
otherwise incomprehensible written messages. In either case, you must
touch the creature or the writing. The ability to read does not
necessarily impart insight into the material, merely its literal
meaning. The spell enables you to understand or read an unknown
language, not speak or write it.</p>
    <p>Written material can be read at the rate of one page (250
words) per minute. Magical writing cannot be read, though the spell
reveals that it is magical. This spell can be foiled by certain warding
magic (such as the secret page and illusory script spells). It does not
decipher codes or reveal messages concealed in otherwise normal text.</p>
    <p>Comprehend languages can be made permanent with a permanency
spell.</p>
    <p><i>Arcane Material Component</i>: A pinch of soot and a few
grains of salt.</p>
    <h6><a id="cone-of-cold">Cone of Cold</a></h6>
    <p className="initial"><i>Evocation [Cold]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5, Water 6</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 60 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Reflex half</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>Cone of cold creates an area of extreme cold, originating at
your hand and extending outward in a cone. It drains heat, dealing 1d6
points of cold damage per caster level (maximum 15d6).</p>
    <p><i>Arcane Material Component</i>: A very small crystal or
glass cone.</p>
    <h6><a id="confusion">Confusion</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 4, Trickery
4</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Medium (100 ft. + 10
ft./level)</span> <span className="stat-block"><b>Targets</b>: All
creatures in a
15-ft. radius burst</span> <span className="stat-block"><b>Duration</b>: 1
round/level</span> <span className="stat-block"><b>Saving Throw</b>: Will
negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell causes the targets to become <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a>, making them
unable to independently determine what they will do.</p>
    <p>Roll on the following table at the beginning of each
subject&rsquo;s
turn each round to see what the subject does in that round.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>d%</th>
          <th>Behavior</th>
        </tr>
        <tr className="odd-row">
          <td>01&ndash;10</td>
          <td>Attack caster with melee or ranged weapons (or close
with caster if attack is not possible).</td>
        </tr>
        <tr>
          <td>11&ndash;20</td>
          <td>Act normally.</td>
        </tr>
        <tr className="odd-row">
          <td>21&ndash;50</td>
          <td>Do nothing but babble incoherently.</td>
        </tr>
        <tr>
          <td>51&ndash;70</td>
          <td>Flee away from caster at top possible speed.</td>
        </tr>
        <tr className="odd-row">
          <td>71&ndash;100</td>
          <td>Attack nearest creature (for this purpose, a familiar
counts as part of the subject&rsquo;s self).</td>
        </tr>
      </tbody>
    </table>
    <p>A confused character who can&rsquo;t carry out the indicated
action
does nothing but babble incoherently. Attackers are not at any special
advantage when attacking a confused character. Any confused character
who is attacked automatically attacks its attackers on its next turn,
as long as it is still confused when its turn comes. Note that a
confused character will not make attacks of opportunity against any
creature that it is not already devoted to attacking (either because of
its most recent action or because it has just been attacked).</p>
    <p><i>Arcane Material Component</i>: A set of three nut shells.</p>
    <h6><a id="lesser-confusion">Confusion, Lesser</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One living
creature</span> <span className="stat-block"><b>Duration</b>: 1 round</span>
    <p>This spell causes a single creature to become <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a> for 1
round. See the <a style={{
        "color": "#579eb6"
      }} href="#confusion">confusion</a> spell, above, to determine the exact effect on
the subject.</p>
    <h6><a id="consecrate">Consecrate</a></h6>
    <p className="initial"><i>Evocation [Good]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 2</span> <span className="stat-block"><b>Components</b>: V, S, M, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Area</b>: 20-ft.-radius
emanation</span> <span className="stat-block"><b>Duration</b>: 2
hours/level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell blesses an area with positive energy. Each Charisma
check made to <a href="specialAttacks.html#turn" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn undead</a> within this area
gains a +3 sacred bonus.
Every undead creature entering a consecrated area suffers minor
disruption, giving it a &ndash;1 penalty on attack rolls, damage rolls,
and
saves. Undead cannot be created within or summoned into a consecrated
area.</p>
    <p>If the consecrated area contains an altar, shrine, or other
permanent fixture dedicated to your deity, pantheon, or aligned higher
power, the modifiers given above are doubled (+6 sacred bonus on
turning checks, &ndash;2 penalties for undead in the area). You cannot
consecrate an area with a similar fixture of a deity other than your
own patron.</p>
    <p>If the area does contain an altar, shrine, or other permanent
fixture of a deity, pantheon, or higher power other than your patron,
the consecrate spell instead curses the area, cutting off its
connection with the associated deity or power. This secondary function,
if used, does not also grant the bonuses and penalties relating to
undead, as given above.</p>
    <p>Consecrate counters and dispels desecrate.</p>
    <p><i>Material Component</i>: A vial of holy water and 25 gp
worth (5 pounds) of silver dust, all of which must be sprinkled around
the area.</p>
    <h6><a id="contact-other-plane">Contact Other Plane</a></h6>
    <p className="initial"><i>Divination</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 5</span> <span className="stat-block"><b>Components</b>: V</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>:
Concentration</span>
    <p>You send your mind to another plane of existence (an Elemental
Plane or some plane farther removed) in order to receive advice and
information from powers there. (See the accompanying table for possible
consequences and results of the attempt.) The powers reply in a
language you understand, but they resent such contact and give only
brief answers to your questions. (All questions are answered with
&ldquo;yes,&rdquo; &ldquo;no,&rdquo; &ldquo;maybe,&rdquo;
&ldquo;never,&rdquo; &ldquo;irrelevant,&rdquo; or some other one-word
answer.)</p>
    <p>You must concentrate on maintaining the spell (a standard
action) in order to ask questions at the rate of one per round. A
question is answered by the power during the same round. For every two
caster levels, you may ask one question.</p>
    <p>Contact with minds far removed from your home plane increases
the probability that you will incur a decrease to Intelligence and
Charisma, but the chance of the power knowing the answer, as well as
the probability of the entity answering correctly, are likewise
increased by moving to distant planes.</p>
    <p>Once the Outer Planes are reached, the power of the deity
contacted determines the effects. (Random results obtained from the
table are subject to the personalities of individual deities.)</p>
    <p>On rare occasions, this divination may be blocked by an act of
certain deities or forces.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th>Plane Contacted</th>
          <th>Avoid Int/Cha
Decrease</th>
          <th>True Answer</th>
          <th>Don&rsquo;t Know</th>
          <th>Lie</th>
          <th>Random Answer</th>
        </tr>
        <tr className="odd-row">
          <td>Elemental Plane</td>
          <td>DC 7/1 week</td>
          <td>01&ndash;34</td>
          <td>35&ndash;62</td>
          <td>63&ndash;83</td>
          <td>84&ndash;100</td>
        </tr>
        <tr>
          <td>&nbsp; &nbsp;(appropriate)</td>
          <td>(DC 7/1 week)</td>
          <td>(01&ndash;68)</td>
          <td>(69&ndash;75)</td>
          <td>(76&ndash;98)</td>
          <td>(99&ndash;100)</td>
        </tr>
        <tr className="odd-row">
          <td>Positive/Negative Energy Plane</td>
          <td>DC 8/1 week</td>
          <td>01&ndash;39</td>
          <td>40&ndash;65</td>
          <td>66&ndash;86</td>
          <td>87&ndash;100</td>
        </tr>
        <tr>
          <td>Astral Plane</td>
          <td>DC 9/1 week</td>
          <td>01&ndash;44</td>
          <td>45&ndash;67</td>
          <td>68&ndash;88</td>
          <td>89&ndash;100</td>
        </tr>
        <tr className="odd-row">
          <td>Outer Plane, demideity</td>
          <td>DC 10/2 weeks</td>
          <td>01&ndash;49</td>
          <td>50&ndash;70</td>
          <td>71&ndash;91</td>
          <td>92&ndash;100</td>
        </tr>
        <tr>
          <td>Outer Plane, lesser deity</td>
          <td>DC 12/3 weeks</td>
          <td>01&ndash;60</td>
          <td>61&ndash;75</td>
          <td>76&ndash;95</td>
          <td>96&ndash;100</td>
        </tr>
        <tr className="odd-row">
          <td>Outer Plane, intermediate deity</td>
          <td>DC 14/4 weeks</td>
          <td>01&ndash;73</td>
          <td>74&ndash;81</td>
          <td>82&ndash;98</td>
          <td>99&ndash;100</td>
        </tr>
        <tr>
          <td className="last-row">Outer Plane, greater deity</td>
          <td className="last-row">DC 16/5 weeks</td>
          <td className="last-row">01&ndash;88</td>
          <td className="last-row">89&ndash;90</td>
          <td className="last-row">91&ndash;99</td>
          <td className="last-row">100</td>
        </tr>
      </tbody>
    </table>
    <p><i>Avoid Int/Cha Decrease</i>: You must succeed on an
Intelligence check against this DC to avoid a decrease in Intelligence
and Charisma. If the check fails, your Intelligence and Charisma scores
each fall to 8 for the stated duration, and you become unable to cast
arcane spells. If you lose Intelligence and Charisma, the effect
strikes as soon as the first question is asked, and no answer is
received. (The entries in parentheses are for questions that pertain to
the appropriate Elemental Plane.)</p>
    <p><i>Results of a Successful Contact</i>: d% is rolled for the
result shown on the table:</p>
    <p><i>True Answer</i>: You get a true, one-word answer. Questions
that cannot be answered in this way are answered randomly.</p>
    <p><i>Don&rsquo;t Know</i>: The entity tells you that it
doesn&rsquo;t know.</p>
    <p><i>Lie</i>: The entity intentionally lies to you.</p>
    <p><i>Random Answer</i>: The entity tries to lie but
doesn&rsquo;t know
the answer, so it makes one up.</p>
    <h6><a id="contagion">Contagion</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Destruction 3, Drd
3, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Living creature touched</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>The subject contracts a disease selected from the table below,
which strikes immediately (no incubation period). The DC noted is for
the subsequent saves (use contagion&rsquo;s normal save DC for the
initial
saving throw).</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>Disease</th>
          <th>DC</th>
          <th>Damage</th>
        </tr>
        <tr className="odd-row">
          <td>Blinding sickness</td>
          <td>16</td>
          <td>1d4 Str<sup>1</sup></td>
        </tr>
        <tr>
          <td>Cackle fever</td>
          <td>16</td>
          <td>1d6 Wis</td>
        </tr>
        <tr className="odd-row">
          <td>Filth fever</td>
          <td>12</td>
          <td>1d3 Dex and 1d3 Con</td>
        </tr>
        <tr>
          <td>Mindfire</td>
          <td>12</td>
          <td>1d4 Int</td>
        </tr>
        <tr className="odd-row">
          <td>Red ache</td>
          <td>15</td>
          <td>1d6 Str</td>
        </tr>
        <tr>
          <td>Shakes</td>
          <td>13</td>
          <td>1d8 Dex</td>
        </tr>
        <tr className="odd-row">
          <td>Slimy doom</td>
          <td>14</td>
          <td>1d4 Con</td>
        </tr>
        <tr>
          <td colSpan="3">1 <i>Each time a victim takes 2 or more
points of Strength damage from blinding sickness, he or she must make
another Fortitude save (using the disease&rsquo;s save DC) or be
permanently <a href="abilitiesAndConditions.html#blinded" style={{
                "color": "rgb(87, 158, 182)"
              }}>blinded</a>.</i></td>
        </tr>
      </tbody>
    </table>
    <h6><a id="contingency">Contingency</a></h6>
    <p className="initial"><i>Evocation</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M, F</span> <span className="stat-block"><b>Casting Time</b>: At least 10
minutes; see text</span> <span className="stat-block"><b>Range</b>:
Personal</span> <span className="stat-block"><b>Target</b>: You</span> <span className="stat-block"><b>Duration</b>: One day/level (D) or
until discharged</span>
    <p>You can place another spell upon your person so that it comes
into effect under some condition you dictate when casting contingency.
The contingency spell and the companion spell are cast at the same
time. The 10-minute casting time is the minimum total for both
castings; if the companion spell has a casting time longer than 10
minutes, use that instead.</p>
    <p>The spell to be brought into effect by the contingency must be
one that affects your person and be of a spell level no higher than
one-third your caster level (rounded down, maximum 6th level).</p>
    <p>The conditions needed to bring the spell into effect must be
clear, although they can be general. In all cases, the contingency
immediately brings into effect the companion spell, the latter being
&ldquo;cast&rdquo; instantaneously when the prescribed circumstances
occur. If
complicated or convoluted conditions are prescribed, the whole spell
combination (contingency and the companion magic) may fail when called
on. The companion spell occurs based solely on the stated conditions,
regardless of whether you want it to.</p>
    <p>You can use only one contingency spell at a time; if a second
is cast, the first one (if still active) is dispelled.</p>
    <p><i>Material Component</i>: That of the companion spell, plus
quicksilver and an eyelash of an ogre mage, rakshasa, or similar
spell-using creature.</p>
    <p><i>Focus</i>: A statuette of you carved from elephant ivory
and decorated with gems (worth at least 1,500 gp). You must carry the
focus for the contingency to work.</p>
    <h6><a id="continual-flame">Continual Flame</a></h6>
    <p className="initial"><i>Evocation [Light]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3, Sor/Wiz 2</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Object touched</span> <span className="stat-block"><b>Effect</b>: Magical,
heatless flame</span> <span className="stat-block"><b>Duration</b>:
Permanent</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A flame, equivalent in brightness to a torch, springs forth
from an object that you touch. The effect looks like a regular flame,
but it creates no heat and doesn&rsquo;t use oxygen. A continual flame
can be
covered and hidden but not smothered or quenched.</p>
    <p>Light spells counter and dispel darkness spells of an equal or
lower level.</p>
    <p><i>Material Component</i>: You sprinkle ruby dust (worth 50
gp) on the item that is to carry the flame.</p>
    <h6><a id="control-plants">Control Plants</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Drd 8, Plant 8</span> <span className="stat-block"><b>Components</b>: V, S, DF</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Up to 2
HD/level of
plant creatures, no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell enables you to control the actions of one or more
plant creatures for a short period of time. You command the creatures
by voice and they understand you, no matter what language you speak.
Even if vocal communication is impossible the controlled plants do not
attack you. At the end of the spell, the subjects revert to their
normal behavior.</p>
    <p>Suicidal or self-destructive commands are simply ignored.</p>
    <h6><a id="control-undead">Control Undead</a></h6>
    <p className="initial"><i>Necromancy</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Targets</b>: Up to 2
HD/level of
undead creatures, no two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>This spell enables you to command undead creatures for a short
period of time. You command them by voice and they understand you, no
matter what language you speak. Even if vocal communication is
impossible the controlled undead do not attack you. At the end of the
spell, the subjects revert to their normal behavior.</p>
    <p>Intelligent undead creatures remember that you controlled them.</p>
    <p><i>Material Component</i>: A small piece of bone and a small
piece of raw meat.</p>
    <h6><a id="control-water">Control Water</a></h6>
    <p className="initial"><i>Transmutation [Water]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 4, Drd 4, Sor/Wiz 6,
Water 4</span> <span className="stat-block"><b>Components</b>: V, S, M/DF</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Long (400 ft. + 40
ft./level)</span> <span className="stat-block"><b>Area</b>: Water in a
volume of 10
ft./level by 10 ft./level by 2 ft./level (S)</span> <span className="stat-block"><b>Duration</b>: 10 min./level (D)</span> <span className="stat-block"><b>Saving Throw</b>: None; see text</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>Depending on the version you choose, the control water spell
raises or lowers water.</p>
    <p><i>Lower Water</i>: This causes water or similar liquid to
reduce its depth by as much as 2 feet per caster level (to a minimum
depth of 1 inch). The water is lowered within a squarish depression
whose sides are up to caster level x 10 feet long. In extremely large
and deep bodies of water, such as a deep ocean, the spell creates a
whirlpool that sweeps ships and similar craft downward, putting them at
risk and rendering them unable to leave by normal movement for the
duration of the spell. When cast on water elementals and other
water-based creatures, this spell acts as a slow spell (Will negates).
The spell has no effect on other creatures.</p>
    <p><i>Raise Water</i>: This causes water or similar liquid to
rise in height, just as the lower water version causes it to lower.
Boats raised in this way slide down the sides of the hump that the
spell creates. If the area affected by the spell includes riverbanks, a
beach, or other land nearby, the water can spill over onto dry land.</p>
    <p>With either version, you may reduce one horizontal dimension
by half and double the other horizontal dimension.</p>
    <p><i>Arcane Material Component</i>: A drop of water (for raise
water) or a pinch of dust (for lower water).</p>
    <h6><a id="control-weather">Control Weather</a></h6>
    <p className="initial"><i>Transmutation</i></p>
    <span className="stat-block"><b>Level</b>: Air 7, Clr 7, Drd 7,
Sor/Wiz 7</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 10 minutes; see text</span>
    <span className="stat-block"><b>Range</b>: 2 miles</span> <span className="stat-block"><b>Area</b>: 2-mile-radius circle,
centered on you; see text</span> <span className="stat-block"><b>Duration</b>:
4d12 hours; see text</span> <span className="stat-block"><b>Saving Throw</b>:
None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>You change the weather in the local area. It takes 10 minutes
to cast the spell and an additional 10 minutes for the effects to
manifest. You can call forth weather appropriate to the climate and
season of the area you are in.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Season</th>
          <th>Possible Weather</th>
        </tr>
        <tr className="odd-row">
          <td>Spring</td>
          <td>Tornado, thunderstorm, sleet storm, or hot weather</td>
        </tr>
        <tr>
          <td>Summer</td>
          <td>Torrential rain, heat wave, or hailstorm</td>
        </tr>
        <tr className="odd-row">
          <td>Autumn</td>
          <td>Hot or cold weather, fog, or sleet</td>
        </tr>
        <tr>
          <td>Winter</td>
          <td>Frigid cold, blizzard, or thaw</td>
        </tr>
        <tr className="odd-row">
          <td>Late winter</td>
          <td>Hurricane-force winds or early spring (coastal area)</td>
        </tr>
      </tbody>
    </table>
    <p>You control the general tendencies of the weather, such as the
direction and intensity of the wind. You cannot control specific
applications of the weather&mdash;where lightning strikes, for example,
or
the exact path of a tornado. When you select a certain weather
condition to occur, the weather assumes that condition 10 minutes later
(changing gradually, not abruptly). The weather continues as you left
it for the duration, or until you use a standard action to designate a
new kind of weather (which fully manifests itself 10 minutes later).
Contradictory conditions are not possible simultaneously.</p>
    <p>Control weather can do away with atmospheric phenomena
(naturally occurring or otherwise) as well as create them.</p>
    <p>A druid casting this spell doubles the duration and affects a
circle with a 3-mile radius.</p>
    <h6><a id="control-winds">Control Winds</a></h6>
    <p className="initial"><i>Transmutation [Air]</i></p>
    <span className="stat-block"><b>Level</b>: Air 5, Drd 5</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 40 ft./level</span> <span className="stat-block"><b>Area</b>: 40 ft./level radius
cylinder 40 ft. high</span> <span className="stat-block"><b>Duration</b>:
10 min./level</span> <span className="stat-block"><b>Saving Throw</b>:
Fortitude negates</span> <span className="stat-block"><b>Spell Resistance</b>:
No</span>
    <p>You alter wind force in the area surrounding you. You can make
the wind blow in a certain direction or manner, increase its strength,
or decrease its strength. The new wind direction and strength persist
until the spell ends or until you choose to alter your handiwork, which
requires concentration. You may create an &ldquo;eye&rdquo; of calm air
up to 80
feet in diameter at the center of the area if you so desire, and you
may choose to limit the area to any cylindrical area less than your
full limit.</p>
    <p><i>Wind Direction</i>: You may choose one of four basic wind
patterns to function over the spell&rsquo;s area.</p>
    <p>&bull; A downdraft blows from the center outward in equal
strength
in all directions.</p>
    <p>&bull; An updraft blows from the outer edges in toward the
center
in equal strength from all directions, veering upward before impinging
on the eye in the center.</p>
    <p>&bull; A rotation causes the winds to circle the center in
clockwise or counterclockwise fashion.</p>
    <p>&bull; A blast simply causes the winds to blow in one
direction
across the entire area from one side to the other.</p>
    <p><i>Wind Strength</i>: For every three caster levels, you can
increase or decrease wind strength by one level. Each round on your
turn, a creature in the wind must make a Fortitude save or suffer the
effect of being in the windy area.</p>
    <p>Strong winds (21+ mph) make sailing difficult.</p>
    <p>A severe wind (31+ mph) causes minor ship and building damage.</p>
    <p>A windstorm (51+ mph) drives most flying creatures from the
skies, uproots small trees, knocks down light wooden structures, tears
off roofs, and endangers ships.</p>
    <p>Hurricane force winds (75+ mph) destroy wooden buildings,
sometimes uproot even large trees, and cause most ships to founder.</p>
    <p>A tornado (175+ mph) destroys all nonfortified buildings and
often uproots large trees.</p>
    <h6><a id="create-food-and-water">Create Food and Water</a></h6>
    <p className="initial"><i>Conjuration (Creation)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 3</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 10 minutes</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Food and water
to sustain
three humans or one horse/level for 24 hours</span> <span className="stat-block"><b>Duration</b>: 24 hours; see text</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>The food that this spell creates is simple fare of your
choice&mdash;highly nourishing, if rather bland. Food so created decays
and
becomes inedible within 24 hours, although it can be kept fresh for
another 24 hours by casting a purify food and water spell on it. The
water created by this spell is just like clean rain water, and it
doesn&rsquo;t go bad as the food does.</p>
    <h6><a id="create-greater-undead">Create Greater Undead</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Death 8, Sor/Wiz 8</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#create-undead">create undead</a>, except that you can
create more powerful and intelligent sorts of undead: shadows, wraiths,
spectres, and devourers. The type or types of undead you can create is
based on your caster level, as shown on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Caster Level</th>
          <th style={{
            "width": "125px"
          }}>Undead Created</th>
        </tr>
        <tr className="odd-row">
          <td>15th or lower</td>
          <td><a href="monstersS.html#shadow" style={{
              "color": "rgb(87, 158, 182)"
            }}>Shadow</a></td>
        </tr>
        <tr>
          <td>16th&ndash;17th</td>
          <td><a href="monstersTtoZ.html#wraith" style={{
              "color": "rgb(87, 158, 182)"
            }}>Wraith</a></td>
        </tr>
        <tr className="odd-row">
          <td>18th&ndash;19th</td>
          <td><a href="monstersS.html#spectre" style={{
              "color": "rgb(87, 158, 182)"
            }}>Spectre</a></td>
        </tr>
        <tr>
          <td className="last-row">20th or higher</td>
          <td className="last-row"><a href="monstersDtoDe.html#devourer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Devourer</a></td>
        </tr>
      </tbody>
    </table>
    <h6><a id="create-undead">Create Undead</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 6, Death 6, Evil 6,
Sor/Wiz 6</span> <span className="stat-block"><b>Components</b>: V, S, M</span>
    <span className="stat-block"><b>Casting Time</b>: 1 hour</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One corpse</span>
    <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span> <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>A much more potent spell than animate dead, this evil spell
allows you to create more powerful sorts of undead: ghouls, ghasts,
mummies, and mohrgs. The type or types of undead you can create is
based on your caster level, as shown on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Caster Level</th>
          <th style={{
            "width": "125px"
          }}>Undead Created</th>
        </tr>
        <tr className="odd-row">
          <td>11th or lower</td>
          <td><a href="monstersG.html#ghoul" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ghoul</a></td>
        </tr>
        <tr>
          <td>12th&ndash;14th</td>
          <td><a href="monstersG.html#ghoul" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ghast</a></td>
        </tr>
        <tr className="odd-row">
          <td>15th&ndash;17th</td>
          <td><a href="monstersMtoN.html#mummy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mummy</a></td>
        </tr>
        <tr>
          <td className="last-row">18th or higher</td>
          <td className="last-row"><a href="monstersMtoN.html#mohrg" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mohrg</a></td>
        </tr>
      </tbody>
    </table>
    <p>You may create less powerful undead than your level would
allow if you choose. Created undead are not automatically under the
control of their animator. If you are capable of commanding undead, you
may attempt to command the undead creature as it forms.</p>
    <p>This spell must be cast at night.</p>
    <p><i>Material Component</i>: A clay pot filled with grave dirt
and another filled with brackish water. The spell must be cast on a
dead body. You must place a black onyx gem worth at least 50 gp per HD
of the undead to be created into the mouth or eye socket of each
corpse. The magic of the spell turns these gems into worthless shells.</p>
    <h6><a id="create-water">Create Water</a></h6>
    <p className="initial"><i>Conjuration (Creation) [Water]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 0, Drd 0, Pal 1</span>
    <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Effect</b>: Up to 2 gallons
of
water/level</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>This spell generates wholesome, drinkable water, just like
clean rain water. Water can be created in an area as small as will
actually contain the liquid, or in an area three times as
large&mdash;possibly creating a downpour or filling many small
receptacles.</p>
    <p><i>Note</i>: Conjuration spells can&rsquo;t create substances
or
objects within a creature. Water weighs about 8 pounds per gallon. One
cubic foot of water contains roughly 8 gallons and weighs about 60
pounds.</p>
    <h6><a id="creeping-doom">Creeping Doom</a></h6>
    <p className="initial"><i>Conjuration (Summoning)</i></p>
    <span className="stat-block"><b>Level</b>: Drd 7</span> <span className="stat-block"><b>Components</b>: V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 round</span> <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)/ 100 ft.; see text</span> <span className="stat-block"><b>Effect</b>:
One swarm of centipedes
per two levels</span> <span className="stat-block"><b>Duration</b>: 1
min./level</span> <span className="stat-block"><b>Saving Throw</b>: None</span>
    <span className="stat-block"><b>Spell Resistance</b>: No</span>
    <p>When you utter the spell of creeping doom, you call forth a
mass of centipede swarms (one per two caster levels, to a maximum of
ten swarms at 20th level), which need not appear adjacent to one
another.</p>
    <p>You may summon the centipede swarms so that they share the
area of other creatures. The swarms remain stationary, attacking any
creatures in their area, unless you command the creeping doom to move
(a standard action). As a standard action, you can command any number
of the swarms to move toward any prey within 100 feet of you. You
cannot command any swarm to move more than 100 feet away from you, and
if you move more than 100 feet from any swarm, that swarm remains
stationary, attacking any creatures in its area (but it can be
commanded again if you move within 100 feet).</p>
    <h6><a id="crushing-despair">Crushing Despair</a></h6>
    <p className="initial"><i>Enchantment (Compulsion) [Mind-Affecting]</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Sor/Wiz 4</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 standard action</span> <span className="stat-block"><b>Range</b>: 30 ft.</span> <span className="stat-block"><b>Area</b>: Cone-shaped burst</span> <span className="stat-block"><b>Duration</b>: 1 min./level</span> <span className="stat-block"><b>Saving Throw</b>: Will negates</span> <span className="stat-block"><b>Spell Resistance</b>: Yes</span>
    <p>An <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> cone of despair causes
great sadness in the
subjects. Each affected creature takes a &ndash;2 penalty on attack
rolls,
saving throws, ability checks, skill checks, and weapon damage rolls.</p>
    <p>Crushing despair counters and dispels good hope.</p>
    <p><i>Material Component</i>: A vial of tears.</p>
    <h6><a id="crushing-hand">Crushing Hand</a></h6>
    <p className="initial"><i>Evocation [Force]</i></p>
    <span className="stat-block"><b>Level</b>: Sor/Wiz 9, Strength 9</span>
    <span className="stat-block"><b>Components</b>: V, S, M, F/DF</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#interposing-hand">interposing hand</a>, except that the
hand can interpose itself, push, or crush one opponent that you select.</p>
    <p>The crushing hand can <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> an opponent like
grasping hand
does. Its grapple bonus equals your caster level + your Intelligence,
Wisdom, or Charisma modifier (for a wizard, cleric, or sorcerer,
respectively), +12 for the hand&rsquo;s Strength score (35), +4 for
being
Large. The hand deals 2d6+12 points of damage (lethal, not nonlethal)
on each successful grapple check against an opponent.</p>
    <p>The crushing hand can also interpose itself as interposing
hand does, or it can <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull rush</a> an opponent as
forceful hand does, but
at a +18 bonus.</p>
    <p>Directing the spell to a new target is a move action.</p>
    <p>Clerics who cast this spell name it for their deities.</p>
    <p><i>Arcane Material Component</i>: The shell of an egg.</p>
    <p><i>Arcane Focus</i>: A glove of snakeskin.</p>
    <h6><a id="cure-critical-wounds">Cure Critical Wounds</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 4, Clr 4, Drd 5,
Healing 4</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#cure-light-wound">cure light wounds</a>, except that it
cures 4d8 points of damage +1 point per caster level (maximum +20).</p>
    <h6><a id="mass-cure-critical-wounds">Cure Critical Wounds, Mass</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 8, Drd 9, Healing 8</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mass-cure-light-wounds">mass cure light wounds</a>, except that
it cures 4d8 points of damage +1 point per caster level (maximum +40).</p>
    <h6><a id="cure-light-wounds">Cure Light Wounds</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 1, Clr 1, Drd 1,
Healing 1, Pal 1, Rgr 2</span> <span className="stat-block"><b>Components</b>:
V, S</span> <span className="stat-block"><b>Casting Time</b>: 1 standard
action</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>: Creature touched</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will half
(harmless); see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes (harmless);
see text</span>
    <p>When laying your hand upon a living creature, you channel
positive energy that cures 1d8 points of damage +1 point per caster
level (maximum +5).</p>
    <p>Since undead are powered by negative energy, this spell deals
damage to them instead of curing their wounds. An undead creature can
apply spell resistance, and can attempt a Will save to take half damage.</p>
    <h6><a id="mass-cure-light-wounds">Cure Light Wounds, Mass</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 5, Clr 5, Drd 6,
Healing 5</span> <span className="stat-block"><b>Components</b>: V, S</span>
    <span className="stat-block"><b>Casting Time</b>: 1 standard action</span>
    <span className="stat-block"><b>Range</b>: Close (25 ft. + 5 ft./2
levels)</span> <span className="stat-block"><b>Target</b>: One
creature/level, no
two of which can be more than 30 ft. apart</span> <span className="stat-block"><b>Duration</b>: Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>: Will half
(harmless) or Will half; see text</span> <span className="stat-block"><b>Spell
Resistance</b>: Yes (harmless)
or Yes; see text</span>
    <p>You channel positive energy to cure 1d8 points of damage +1
point per caster level (maximum +25) in each selected creature.</p>
    <p>Like other cure spells, mass cure light wounds deals damage to
undead in its area rather than curing them. Each affected undead may
attempt a Will save for half damage.</p>
    <h6><a id="cure-minor-wounds">Cure Minor Wounds</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 0, Drd 0</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#cure-light-wounds">cure light wounds</a>, except that it
cures only 1 point of damage.</p>
    <h6><a id="cure-moderate-wounds">Cure Moderate Wounds</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 2, Clr 2, Drd 3,
Healing 2, Pal 3, Rgr 3</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#cure-light-wounds">cure light wounds</a>, except that it
cures 2d8 points of damage +1 point per caster level (maximum +10).</p>
    <h6><a id="mass-cure-moderate-wounds">Cure Moderate Wounds, Mass</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 6, Clr 6, Drd 7</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mass-cure-light-wounds">mass cure light wounds</a>, except that
it cures 2d8 points of damage +1 point per caster level (maximum +30).</p>
    <h6><a id="cure-serious-wounds">Cure Serious Wounds</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Brd 3, Clr 3, Drd 4, Pal
4, Rgr 4, Healing 3</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#cure-light-wounds">cure light wounds</a>, except that it
cures 3d8 points of damage +1 point per caster level (maximum +15).</p>
    <h6><a id="mass-cure-serious-wounds">Cure Serious Wounds, Mass</a></h6>
    <p className="initial"><i>Conjuration (Healing)</i></p>
    <span className="stat-block"><b>Level</b>: Clr 7, Drd 8</span>
    <p>This spell functions like <a style={{
        "color": "#579eb6"
      }} href="#mass-cure-light-wounds">mass cure light wounds</a>, except that
it cures 3d8 points of damage +1 point per caster level (maximum +35).</p>
    <h6><a id="curse-water">Curse Water</a></h6>
    <p className="initial"><i>Necromancy [Evil]</i></p>
    <span className="stat-block"><b>Level</b>: Clr 1</span> <span className="stat-block"><b>Components</b>: V, S, M</span> <span className="stat-block"><b>Casting Time</b>: 1 minute</span> <span className="stat-block"><b>Range</b>: Touch</span> <span className="stat-block"><b>Target</b>:
Flask of water touched</span> <span className="stat-block"><b>Duration</b>:
Instantaneous</span> <span className="stat-block"><b>Saving Throw</b>:
Will negates
(object)</span> <span className="stat-block"><b>Spell Resistance</b>: Yes
(object)</span>
    <p>This spell imbues a flask (1 pint) of water with negative
energy, turning it into unholy water. Unholy water damages good
outsiders the way holy water damages undead and evil outsiders.</p>
    <p><i>Material Component</i>: 5 pounds of powdered silver (worth
25 gp).</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      